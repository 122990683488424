import React from 'react';

const HeaderComponent = ()=>{

    return (
        <div>
            <h2>Ticket generator</h2>
        </div>
    );

};

export {HeaderComponent};